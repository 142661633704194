











import { Vue, Component } from 'vue-property-decorator'
import { fetchArticleList } from '@/api/portfolio'

@Component({
  name: 'rTagCom'
})
export default class extends Vue {
  private activeName = ''
  private isFirst: Boolean = true
  private articleArr = [{}]
  handleClick() {

  }

  created() {
    this.initFetch()
  }

  async initFetch() {
    const res = await fetchArticleList({ articleType: 'Family' })
    if (res.statusCode === 0 && res.data) {
      if (res.data.length) {
        res.data.forEach((item: any) => {
          item.picList = item.prcUrl.split(',')
        })
      }
      console.log('====', res.data)
      this.articleArr = res.data
      this.activeName = '1';
      (document.querySelector('.article-wraper .el-tabs__nav .el-tabs__item') as HTMLDivElement).click()
    }
  }
}
