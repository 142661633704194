import { render, staticRenderFns } from "./rTagCom.vue?vue&type=template&id=37541a23&scoped=true&"
import script from "./rTagCom.vue?vue&type=script&lang=ts&"
export * from "./rTagCom.vue?vue&type=script&lang=ts&"
import style0 from "./rTagCom.vue?vue&type=style&index=0&id=37541a23&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37541a23",
  null
  
)

export default component.exports