




































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { AsListInterface, AsDetailIterface, AsDetailItem } from '@/types/kyc'
import { getAssetsInfo, saveAssetItem } from '@/api/asKYC'
import { filterReqData, addDefault } from '../../portfolio/portKYC/asComponents/tools'
import { Drawer } from 'element-ui'
import { CommonMudule } from '@/store/modules/common'
import { getInputValue } from '@/utils/validate'

@Component({
  name: 'drawItem',
  components: {
    handleBtn,
    moduleHeader
  }
})
export default class extends Vue {
  @Prop({ required: true }) private data!: AsListInterface
    private detailObj: AsDetailIterface = {
      assetInputType: null,
      customerAssetSubList: [],
      customerId: '',
      parentAssetTypeId: '',
      parentAssetTypeName: ''
    }
    dialog = false
    loading = false
    private inputData = this.data
    private value = 0
    private options2= [
      {
        value: 'currency-id-0001',
        label: '人民币'
      }
    ]
    // get options() {
    //   return CommonMudule.curencyData
    // }

    async fetchDetail() {
      const res = await getAssetsInfo({
        assetTypeId: this.data.id,
        customerId: CommonMudule.customerId
      })
      if (res.statusCode === 0 && res.data) {
        this.dialog = true
        this.detailObj = addDefault(res.data)
      }
    }

    private blurFilter(index:number, idx: number, value: any, assetAmount: number) {
      const e:Event = window.event as Event
      const val = getInputValue(e)
      console.log('val', val)
      if (!value) {
        this.detailObj.customerAssetSubList[index].customerAssetList[idx].isClear = true
      }
      console.log(' this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount', this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount)
      this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount = val
    }

    private addInfo(addData: AsDetailItem, asIndex: number) {
      this.detailObj.customerAssetSubList[asIndex].customerAssetList.push({
        deleted: false,
        assetTypeId: addData.assetTypeId,
        customerId: this.detailObj.customerId,
        customerMemberId: addData.customerMemberId,
        region: null,
        assetAmount: null,
        currencyId: 'currency-id-0001',
        currencyName: null,
        companyType: 'NoneSelfCompany',
        systemAssetType: addData.systemAssetType
      })
    }

    private removeInfo(index:number, idx: number) {
      this.detailObj.customerAssetSubList[index].customerAssetList[idx].deleted = true
    }

    private async confirm() {
      const res = await saveAssetItem(filterReqData(this.detailObj))
      if (res.statusCode === 0 && res.data) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.dialog = false
        this.$emit('refresh')
      }
    }

    private handleClose() {
      if (this.loading) {
        return
      }
      this.loading = false
      this.dialog = false
    }

    cancelForm() {
      this.loading = false
      this.dialog = false
    }
    created() {
    }
}
