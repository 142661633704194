





























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { AsListInterface, AsDetailIterface, AsDetailItem, AsBlock } from '@/types/kyc'
import { getAssetsInfo, saveAssetItem } from '@/api/asKYC'
import { filterInsReqData, addInsDefault } from '../../portfolio/portKYC/asComponents/tools'
import { cloneDeep } from 'lodash'
import { CommonMudule } from '@/store/modules/common'
import { getcomplete } from '@/api/common'
import { getInputValue } from '@/utils/validate'

@Component({
  name: 'drawInsCom',
  components: {
    handleBtn,
    moduleHeader
  }
})
export default class extends Vue {
  @Prop({ required: true }) private data!: AsListInterface
    private blockObj: AsBlock = {
      customerId: CommonMudule.customerId,
      assetInputType: '',
      customerAssetSubList: null,
      customerMemberAssetVOList: [],
      parentAssetTypeId: '',
      parentAssetTypeName: ''
    }
      private inputData = this.data
      complete=false
      private value = 0
      dialog = false
      loading = false
      blockDetaillist = {
        customerAssetSubList: [{
          assetTypeName: '终身寿险'
        }, {
          assetTypeName: '重疾险'
        }]
      }

      blurGetVal(blockIndex:number, index:number, idx:number, val: string) {
        if (!val) {
          this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].isClear = true
        }
        const e:Event = window.event as Event
        const value = getInputValue(e)
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].assetAmount = value
      }

      get options() {
        return CommonMudule.curencyData
      }
      async fetchDetail() {
        const res = await getAssetsInfo({
          assetTypeId: this.data.id,
          customerId: CommonMudule.customerId
        })
        if (res.statusCode === 0 && res.data) {
          this.dialog = true
          this.blockObj = addInsDefault(res.data)
        }
      }

      private addInfo(addData: AsDetailItem, blockIndex: number, asIndex: number) {
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[asIndex].customerAssetList.push({
          deleted: false,
          assetTypeId: addData.assetTypeId,
          customerId: CommonMudule.customerId,
          customerMemberId: addData.customerMemberId,
          region: null,
          assetAmount: null,
          currencyId: 'currency-id-0001',
          currencyName: null,
          companyType: 'NoneSelfCompany',
          systemAssetType: addData.systemAssetType
        })
      }

      private removeInfo(blockIndex: number, index:number, idx: number) {
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].deleted = true
      }

      private async confirm() {
        const res = await saveAssetItem(filterInsReqData(this.blockObj))
        if (res.statusCode === 0 && res.data) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.dialog = false
        }
      }

      private handleClose() {
        if (this.loading) {
          return
        }
        this.loading = false
        this.dialog = false
      }

      cancelForm() {
        this.loading = false
        this.dialog = false
      }
      created() {
        this.init()
      }
      async init() {
        const res = await getcomplete({ customerId: CommonMudule.customerId })
        const tg = res.data.find((item:any) => item.id === 'total-insurance-0001')
        this.complete = tg.hadAsset
      }
}
