

































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import drawCom from './asComponents/drawCom.vue'
import drawInsCom from './asComponents/drawIns.vue'
import { getAssetsList, getFourTypeData } from '@/api/asKYC'
import { MyCompony, OptArr, AssetsType, AslistItem, SeriesType } from '@/types/kyc'
import { getcomplete } from '@/api/common'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'

@Component({
  name: 'asKYC',
  components: {
    moduleHeader,
    drawCom,
    pieChart,
    drawInsCom
  }
})

export default class extends Vue {
  private riskOption: OptArr = [{ label: '222', value: '333' }]
  private assetsList:AslistItem[]= []
  private typeData: AssetsType[] = []
   hadAsset = []
   datTemp = [{
     id: 'total-debt-0001',
     tenantId: null,
     createTime: null,
     createBy: null,
     createByName: null,
     deleted: false,
     calFieldValue: null,
     assetTypeName: '负债',
     systemAssetType: '',
     parentId: '-2',
     seq: 11,
     level: 1,
     assetPerType: null,
     assetInputType: null,
     calFieldParams: null,
   }, {
     id: 'total-insurance-0001',
     tenantId: null,
     createTime: null,
     createBy: null,
     createByName: null,
     deleted: false,
     calFieldValue: null,
     assetTypeName: '保险',
     systemAssetType: '',
     parentId: '-1',
     seq: 12,
     level: 1,
     assetPerType: null,
     assetInputType: 'MemberTab',
     calFieldParams: null,
   }]
   created() {
     this.getTrue()
   }

   preSubmit() {
     this.$router.replace({
       //  name: 'baseHome',
       path: '/basicSecurity/index',
       params: {
         name: 'reset'
       }
     })
   }
   nextSubmit() {
     this.$router.push('/basicSecurity/base')
   }
   async getTrue() {
     const res = await getcomplete({ customerId: CommonMudule.customerId })
     this.hadAsset = res.data
     this.initFetch()
   }
   async initFetch() {
     const data = cloneDeep(this.datTemp)
     data.map((item:any) => {
       const res = this.hadAsset.find((mt:any) => mt.id === item.id)
       // @ts-ignore
       item.visible = res.hadAsset
       return item
     })
     this.assetsList = data
     this.$forceUpdate()
   }
}
