import { AsDetailIterface, AsBlock } from '@/types/kyc'
import { cloneDeep } from 'lodash'
import { delcommafy, comdify } from '@/utils/validate'
export const filterReqData = (data: AsDetailIterface) => {
  const cloneData = cloneDeep(data)
  cloneData.customerAssetSubList.forEach((typeItem: any, index: number) => {
    const temp:any = []
    typeItem.customerAssetList.forEach((itemData: any, idx: number) => {
      if (itemData.isClear) {
        itemData.deleted = true
        itemData.assetAmount = 0
        temp.push(itemData)
      } else {
        if (itemData.assetAmount === null) {
          typeItem.customerAssetList.splice(idx, 1)
        } else {
          itemData.assetAmount = Number(delcommafy(itemData.assetAmount)) * 10000
          temp.push(itemData)
        }
      }
    })
    typeItem.customerAssetList = temp
  })
  return cloneData
}

export const filterInsReqData = (data: AsBlock) => {
  const cloneData = cloneDeep(data)
  cloneData.customerMemberAssetVOList.forEach((blockItem: { customerAssetSubList: { forEach: (arg0: (typeItem: any) => void) => void; }; }) => {
    blockItem.customerAssetSubList.forEach((typeItem: any) => {
      const temp:any = []
      typeItem.customerAssetList.forEach((itemData: any, idx: number) => {
        if (itemData.isClear) {
          itemData.deleted = true
          itemData.assetAmount = 0
          temp.push(itemData)
        } else {
          if (!itemData.assetAmount) {
            typeItem.customerAssetList.splice(idx, 1)
          } else {
            itemData.assetAmount = Number(delcommafy(itemData.assetAmount)) * 10000
            temp.push(itemData)
          }
        }
      })
      typeItem.customerAssetList = temp
    })
  })

  return cloneData
}

export const addDefault = (data: AsDetailIterface) => {
  const temp = cloneDeep(data)
  for (const subItemValue of temp.customerAssetSubList) {
    if (subItemValue.customerAssetList.length === 0) {
      subItemValue.customerAssetList.push({
        deleted: false,
        assetTypeId: subItemValue.assetTypeId,
        customerId: temp.customerId,
        customerMemberId: subItemValue.customerMemberId,
        region: null,
        assetAmount: null,
        currencyId: 'currency-id-0001',
        currencyName: null,
        companyType: 'NoneSelfCompany',
        systemAssetType: subItemValue.systemAssetType
      })
    } else {
      for (const item of subItemValue.customerAssetList) {
        item.assetAmount = comdify(String(Number(item.assetAmount) / 10000))
      }
    }
  }
  return temp
}

export const addInsDefault = (data: AsBlock) => {
  const temp = cloneDeep(data)
  for (const blockValue of temp.customerMemberAssetVOList) {
    for (const subItemValue of blockValue.customerAssetSubList) {
      if (subItemValue.customerAssetList.length === 0) {
        subItemValue.customerAssetList.push({
          deleted: false,
          assetTypeId: subItemValue.assetTypeId,
          customerId: data.customerId,
          customerMemberId: subItemValue.customerMemberId,
          region: null,
          assetAmount: null,
          currencyId: 'currency-id-0001',
          currencyName: null,
          companyType: 'NoneSelfCompany',
          systemAssetType: subItemValue.systemAssetType
        })
      } else {
        for (const item of subItemValue.customerAssetList) {
          item.assetAmount = comdify(String(Number(item.assetAmount) / 10000))
        }
      }
    }
  }
  return temp
}
